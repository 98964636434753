import React from 'react';
import { useAuth } from 'oidc-react';
import { useLocation } from 'react-router-dom';
import Loader from '../components/loader';

interface AuthGuardProps {
  children: React.ReactNode;
}

export const redirUrl = 'redirURL';
export const previous = localStorage.getItem(redirUrl);
export const clearPrevious = () => {
  localStorage.removeItem(redirUrl);
}

const AuthGuard: React.FC<any> = ({ children }: AuthGuardProps) => {
  const auth = useAuth()
  const location = useLocation();
  const isAuthenticated = auth?.userData

  if (auth.isLoading) {
    return <Loader />
  }

  if (!isAuthenticated) {
    localStorage.setItem(redirUrl, location.pathname);
    auth.signIn({
      redirect_uri: window.location.toString(),
    });
    return null;
  }

  return <>{children}</>;
};

export default AuthGuard;
