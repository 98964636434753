import React, { useEffect } from 'react';
import './App.scss';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, Tooltip } from '@mui/material';
import {
  Home as HomeIcon,
  Storage,
  // Menu,
  AccountCircle,
  Add,
  Sell,
} from '@mui/icons-material';
import { useNavigate, Outlet } from 'react-router-dom';
import { AuthProvider } from 'oidc-react';
import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import darkTheme from './theme';
import AuthGuard, { clearPrevious, previous } from './components/protected';
import {
  Cluster,
  CreateCluster,
  Home,
  LinkAccount,
  Pricing,
  PrivacyPolicy,
  Profile,
  SignupForm,
  SingleCluster,
  TOS,
} from './pages'
// import Logo from './logo';

// This configuration use the ServiceWorker mode only
// "access_token" will be provided automaticaly to the urls and domains configured inside "OidcTrustedDomains.js"
const configuration = {
  clientId: 'microcumulus-dash',
  redirectUri: window.location.toString(),
  scope: 'openid profile email offline_access',
  authority: 'https://auth.ucml.dev/realms/microcumulus',
  autoSignIn: false,
  automaticSilentRenew: true,
  postLogoutRedirectUri: window.location.origin,
  // 'https://microcumul.us',
  // service_worker_relative_url:'/OidcServiceWorker.js',
  // service_worker_only:true,
};

interface Nav {
  path: string;
  title: string;
  element: React.ReactElement;
  icon: React.ReactElement;
  children?: Nav[];
}

const children = [{
  path: '/',
  element: <Home />,
  title: 'Home',
}, {
  path: '/cluster/:cluster',
  element: <AuthGuard><SingleCluster /></AuthGuard>,
}, {
  path: '/signup',
  element: <AuthGuard><SignupForm /></AuthGuard>,
}, {
  path: '/privacy',
  element: <PrivacyPolicy />,
}, {
  path: '/tos',
  element: <TOS />,
}, {
  path: '/link',
  element: <AuthGuard><LinkAccount /></AuthGuard>,
  //icon: <Link />,
  title: 'Cluster',
}, {
  path: '/pricing',
  element: <Pricing />,
  icon: <Sell />,
  title: 'Pricing',
}, {
  path: '/clusters',
  element: <AuthGuard><Cluster /></AuthGuard>,
  icon: <Storage />,
  title: 'Cluster List',
  // children: [],
}, {
    path: '/cluster/new',
    title: 'New Cluster',
    icon: <Add />,
    element: <AuthGuard><CreateCluster /></AuthGuard>,
}, {
  path: '/profile',
  element: <AuthGuard><Profile /></AuthGuard>,
  icon: <AccountCircle />,
  title: 'Profile'
}];

export const nav = [{
  path: '/',
  element: <Layout navs={children.filter(c => c.title).map(c => c as Nav) }/>, // Haxx
  children,
}];

export function Layout({ navs }: { navs: Nav[] }) {
  const navigate = useNavigate();

  // TODO
  useEffect(() => {
    if (previous) {
      const p = previous;
      setTimeout(() => {
        clearPrevious();
        navigate(p);
      }, 1);
    }
  }, [previous])

  // <Menu />
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Tooltip title="Home">
            <IconButton onClick={ () => navigate('/') }>
              <HomeIcon />
            </IconButton>
          </Tooltip>
          <Box sx={{ flexGrow: 1 }} />
          {navs.filter(n => !!n.icon).map((n) => (
          <Box key={n.path}>
            <Tooltip title={n.title}>
              <IconButton onClick={ () => navigate(n.path) }>
                {n.icon}
              </IconButton>
            </Tooltip>
          </Box>
          ))}
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  );
}

const router = createBrowserRouter(nav);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <AuthProvider {...configuration}>
          <SnackbarProvider>
            <RouterProvider router={router} />
          </SnackbarProvider>
        </AuthProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
